export enum ANSWER_TYPE {
    RADIOBUTTON = "radiobutton",
    CHECKBOX = "checkbox",
    TEXTFIELD = "textfield",
    SELECTBOX = "selectbox",
    RATING = "rating",
    FIT = "fit",
}

export const PRODUCT_REVIEW_FORM_ID = "gw-rv-product-review-form";
export const REVIEW_DRAWER_FORM_ID = "review_drawer_form_id";
export const SITE_REVIEW_FORM_ID = "gw-rv-site-review-form";
export const QUESTION_FORM_ID = "gw-rv-qa-form";

export enum REVIEW_TYPE {
    SITE_REVIEW = "site_review",
    PRODUCT_REVIEW = "product_review",
}

export enum REVIEWS_WIDGET_NAMES {
    REVIEWS_DRAWER_WIDGET = "reviewsDrawerWidget",
    PRODUCT_REVIEWS_WIDGET = "productReviewsWidget",
    REVIEWS_PAGE_WIDGET = "reviewsPageWidget",
    LEAVE_REVIEW_PAGE_WIDGET = "leaveReviewPageWidget",
}
