import { STATUS_TYPE, VIRGIN } from "@/constants/statuses";
import OffsetPaginationEntity from "@/entities/OffsetPaginationEntity";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { injectable } from "tsyringe";

import ReviewEntity from "../entities/ReviewEntity";

export interface ReviewsStateBase extends EntityState<ReviewEntity, number> {
    paginationMeta: OffsetPaginationEntity;
    canLeaveReview: boolean;
    canLeaveReviewMessage: string | null;
    status: STATUS_TYPE;
    leaveReviewStatus: STATUS_TYPE;
    leaveReviewError: Error | null;
    canLeaveReviewStatus: STATUS_TYPE;
}

function createInitialState(): ReviewsStateBase {
    return {
        paginationMeta: {
            perPage: 0,
            totalCount: 0,
            currentOffset: 0,
        },
        canLeaveReview: false,
        canLeaveReviewMessage: null,
        status: VIRGIN,
        leaveReviewStatus: VIRGIN,
        leaveReviewError: null,
        canLeaveReviewStatus: VIRGIN,
    };
}

@injectable()
@StoreConfig({ name: "reviewsStoreBase" })
class ReviewsStoreBase extends EntityStore<ReviewsStateBase> {
    constructor() {
        super(createInitialState());
    }

    setStatus(status: STATUS_TYPE) {
        this.update(() => ({
            status: status,
        }));
    }

    getStatus(): STATUS_TYPE {
        return this.getValue().status;
    }

    setLeaveReviewStatus(status: STATUS_TYPE) {
        this.update(() => ({
            leaveReviewStatus: status,
        }));
    }
}

export default ReviewsStoreBase;
