import { STATUS_TYPE, VIRGIN } from "@/constants/statuses";
import { Store, StoreConfig } from "@datorama/akita";

import CartItem from "../entities/CartItem";

export type CartState = {
    status: STATUS_TYPE;
    items: CartItem[];
    editItemStatuses: {
        [variantId: number]: STATUS_TYPE;
    };
};

const createInitialState = (): CartState => {
    return {
        status: VIRGIN,
        items: [],
        editItemStatuses: {},
    };
};

@StoreConfig({ name: "CartStore" })
export class CartStore extends Store<CartState> {
    public constructor() {
        super(createInitialState());
    }
}
