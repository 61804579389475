import { STATUS_TYPE, VIRGIN } from "@/constants/statuses";
import { Query } from "@datorama/akita";
import { Observable } from "rxjs";

import { CartState, CartStore } from "../stores/CartStore";

export class CartQuery extends Query<CartState> {
    public constructor(readonly cartItemsStore: CartStore) {
        super(cartItemsStore);
    }

    selectEditItemStatus(variantId: number): Observable<STATUS_TYPE> {
        return this.select(
            (state) => state.editItemStatuses[variantId] || VIRGIN
        );
    }

    selectQuantityByVariantId(variantId: number): Observable<number> {
        return this.select(
            (state) =>
                state.items.find((item) => item.variant_id === variantId)
                    ?.quantity || 0
        );
    }

    selectCartInfoStatus(): Observable<STATUS_TYPE> {
        return this.select((state) => state.status);
    }
}
