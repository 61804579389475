// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WISHLIST (prefix: 'wl') */
:root,
:host {
}
`, "",{"version":3,"sources":["webpack://./src/themes/default/wishlist.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;;AAEA","sourcesContent":["/* WISHLIST (prefix: 'wl') */\n:root,\n:host {\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
